// import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Countup from "./components/Countup";
import Home from "./pages/Home";
import Introduction from "./pages/aboutus/Introduction";
import SportsReqrmtPolicy from "./pages/Policy/SportsReqrmtPolicy";
import Gallery from "./pages/Gallery/Gallary";
import Harjot from "./pages/Indivisual/principal-secretary-sports-department";
import Vision_Mission from "./pages/Vision_Mission/Vision_Mission";
import Jitender from "./pages/Indivisual/sports-minister";
import Pankaj from "./pages/Indivisual/Pankaj";
import Ravindran from "./pages/Indivisual/Ravindran";
import Kss from "./pages/Kss/Kss";
import PrioritySports from "./pages/PrioritySports/PrioritySports";
import PressGallery from "./pages/Gallery/PressGallery";
import KSSGalleryImg from "./pages/GalleryCategoryWise/Events";
import GalleryCategoryWise from "./pages/GalleryCategoryWise/GalleryPage";
// import NIDJAM from "./pages/GalleryCategoryWise/NIDJAM";
import Videos from "./pages/Gallery/Videos";
import InternationalAchievement from "./pages/achievement/InternationalAchievement";
import Tenders from "./pages/tenders/Tenders";
import RegistrationforKss from "./pages/tenders/RegistrationforKss";
import SportsBudget from "./pages/tenders/SportsBudget";
import RecruitmentNotices from "./pages/tenders/RecruitmentNotices";
import UpcommingEvents from "./pages/tenders/UpcomingEvents";
import GetMedalGetJob from "./pages/tenders/GetMedalGetJob";
import SportInfrastructure from "./pages/tenders/SportsInfrastructure";
import SportLegecy from "./pages/sportLegecy/SportLegecy";
import SportsScholarshipPolicy from "./pages/Policy/SportsScholarshipPolicy";
import Inspiration from "./pages/inspiration/Inspiration";
import Able from "./pages/able/Able";
import AcademicScholarship from './pages/Policy/AcademicScholarship'
import InsuranceSupport from './pages/insuranceSupport/InsuranceSupport'
import NationalAchievement from "./pages/achievement/NationalAchievement";
import DSODirectory from "./pages/DSO/DSODirectory";
import EklavyaKheloCentres from "./pages/services/EklavyaKheloCentres";
import HostelGroundBooking from "./pages/services/HostelGroundBooking";
import Infrastructure from "./pages/services/Infrastructure";
import Schemes from "./pages/services/Schemes";
import SportsDevelopmentPolicy from "./pages/services/SportsDevelopmentPolicy";
import SportsEventsList from "./pages/services/SportsEventList";
import SportsServices from "./pages/services/SportsServices";
import SportsSponsOrg from "./pages/services/SportsSponsOrg";
import Training from "./pages/services/Training";
import SportsWelfareFund from "./pages/Policy/SportsWelfareFund";
import GrantsSportsFederations from "./pages/Policy/GrantsSportsFederations";
import SportEquiInfo from './pages/sportEquiInfo/SportEquiInfo'
import OrganizationalStru from "./pages/organizationalStru/OrganizationalStru";
import ChiefMinister from "./pages/Indivisual/ChiefMinister";
import DeputyChiefMinister from "./pages/Indivisual/DeputyChiefMinister";
import Udaan from "./pages/Udaan/Udaan";
import Message from "./pages/contactus/Message";
import Directory from "./pages/contactus/Directory";
import ContactUs from "./pages/contactus/ContactUs";
import OurPolicy from "./pages/Policy/ourPolicy/OurPolicy";
import KheloIndiaCenter from "./pages/achievement/KheloIndiaCenter";
import StateLevelAchievement from "./pages/achievement/StateLevelAchievement";
import EventsAndPrograms from "./pages/eventsAndPrograms/EventsAndPrograms";
import Disclaimer from "./pages/disclaimer/Disclaimer";
import SportsFederation from "./pages/khelSangh/SportsFederation";
import UpcomingEvents from './pages/eventsAndPrograms/UpcomingEvents'
import EventOrg from './pages/eventsAndPrograms/EventOrg'
import NewHighlight from "./components/NewHighlights";
import NoticesCirculars from "./pages/downloads/NoticesCirculars";
import AnnualReport from "./pages/downloads/AnnualReport";
import Advertisement from "./pages/downloads/Advertisement";
import PressRelease from "./pages/downloads/PressRelease";
import AnnualCalender from "./pages/downloads/AnnualCalender";
import Sitemap from "./pages/sitemap/Sitemap";
import NationalGames from "./pages/NationalGames/NationalGames";
import GameDetailsPage from "./pages/NationalGames/GameDetailsPage";
import NationalSchoolGames from "./pages/SGFI/NationalSchoolGames";
import Athlite from "./pages/SGFI/Athlite";
import Cricket from "./pages/SGFI/Cricket";
import Football from "./pages/SGFI/Football"
import SepakTakra from "./pages/SGFI/SepakTakra";
import Th38_NationalGames from './pages/NationalGames/Th38_NationalGames'
import CareerAndOpportunity from './pages/CareerAndOpportunity/CareerAndOpportunity'
import WeightLifting from './pages/SGFI/WeightLifting'
import Esport from './pages/ESportLinks/ESportLinks'
import ProKabadiPage from './pages/Prokabaddi/ProKabaddiPage'
import SportsPolicy from "./pages/Policy/SportsPolicy";
import BiharWomenKabaddiLeague from "./pages/SGFI/biharWomenKabaddiLeague";
import BiharWomenKabaddiMatchSchedule from "./pages/SGFI/biharWomenKabaddiMatchSchedule";
import Letters from "./pages/downloads/Letters";
import ScheduleTable from "./pages/Olampic/olampicScheduleTable";
import SportsMinister from "./pages/Indivisual/sports-minister";
import PrincipalSecretarySportsDepartment from "./pages/Indivisual/principal-secretary-sports-department";
import Rajgirstadiuminauguration from "./pages/RajgirStadium/rajgirstadium";
import BiharVolleyballLeague from "./pages/BiharVolleyballLeague/BiharVolleyballLeague";

// import NSGIMG from "./pages/GalleryCategoryWise/NSG";

function App() {
  return (
    <div className="App">

      <Router>
        <Routes>

          <Route exact path='/' element={<Home />} />
          <Route path='/aboutus' element={<Introduction />} />
          <Route path='/gallery' element={<Gallery />} />
          {/* <Route path='/organisation' element={<Organisation />} /> */}


          <Route path='/prioritysports' element={<PrioritySports />} />
          <Route path='/visionandmission' element={<Vision_Mission />} />
          <Route path='/kss' element={<Kss />} />
          <Route path='/pressgallery' element={<PressGallery />} />
          <Route path='/GalleryPage' element={<GalleryCategoryWise />} />
          <Route path='/GalleryCategoryWise/Events/:id' element={<KSSGalleryImg />} />
          {/* <Route path='/GalleryCategoryWise/:NSG' element={<NSGIMG />} />
          <Route path='/GalleryCategoryWise/NIDJAM' element={<NIDJAM />} /> */}
          <Route path='/videos' element={<Videos />} />
          <Route path='/tenders' element={<Tenders />} />
          <Route path='/eventsAndPrograms' element={<EventsAndPrograms />} />



          <Route path='/registrationforkss' element={<RegistrationforKss />} />
          <Route path='/sportsbuget' element={<SportsBudget />} />
          <Route path='/recruitmentnotices' element={<RecruitmentNotices />} />

          <Route path='/upcommingevents' element={<UpcommingEvents />} />

          <Route path='/sportlegecy' element={<SportLegecy />} />


          <Route path='/getmedalgetjob' element={<GetMedalGetJob />} />
          <Route path='/sportinfrastructure' element={<SportInfrastructure />} />
          <Route path='/sportsscholarshippolicy' element={<SportsScholarshipPolicy />} />
          <Route path='/inspiration' element={<Inspiration />} />
          <Route path='/able' element={<Able />} />
          <Route path='/academicscholarship' element={<AcademicScholarship />} />
          <Route path='/insuranceSupport' element={<InsuranceSupport />} />

          {/*---------------------------- indivisuals ----------------------  */}
          <Route path='/chiefminister' element={<ChiefMinister />} />
          <Route path='/deputychiefminister' element={<DeputyChiefMinister />} />

          <Route path='/principal-secretary-sports-department' element={<PrincipalSecretarySportsDepartment />} />
          <Route path='/sports-minister' element={<SportsMinister />} />
          <Route path='/pankaj' element={<Pankaj />} />
          <Route path='/ravindran' element={<Ravindran />} />

          {/* ----------------------------Policies ---------------------------------- */}
          <Route path='/sportsreqrmtpolicy' element={<SportsReqrmtPolicy />} />
          <Route path='/sportswelfarefund' element={<SportsWelfareFund />} />
          <Route path='/grantssportsfederations' element={<GrantsSportsFederations />} />
          <Route path='/sportequiinf' element={<SportEquiInfo />} />
          <Route path='/udaan' element={<Udaan />} />
          <Route path='/policy' element={<OurPolicy />} />
          <Route path='/sportsPolicy' element={<SportsPolicy />} />


          {/* ----------------------------------- achivements --------------- */}
          <Route path='/kheloindiacenter' element={<KheloIndiaCenter />} />
          <Route path='/internationalachievement' element={<InternationalAchievement />} />
          <Route path='/nationalachievement' element={<NationalAchievement />} />
          <Route path='/statelevelachievement' element={<StateLevelAchievement />} />

          {/* ----------------------------  ten services ------------------- */}
          <Route path='/dsodirectory' element={<DSODirectory />} />
          <Route path='/eklavykhelocenter' element={<EklavyaKheloCentres />} />
          <Route path='/hostelgroundbooking' element={<HostelGroundBooking />} />
          <Route path='/infrastructure' element={<Infrastructure />} />
          <Route path='/schemes' element={<Schemes />} />
          <Route path='/sportsdevelopmentpolicy' element={<SportsDevelopmentPolicy />} />
          <Route path='/sportseventslist' element={<SportsEventsList />} />
          <Route path='/sportsservices' element={<SportsServices />} />
          <Route path='/sportssponsorg' element={<SportsSponsOrg />} />
          <Route path='/training' element={<Training />} />

          <Route path='/upcomingevents' element={<UpcomingEvents />} />


          <Route path='/organizationalStru' element={<OrganizationalStru />} />

          {/* contact us page */}

          <Route path='/directory' element={<Directory />} />
          <Route path='/query' element={<Message />} />
          {/*---------------------------------- footer section ----------------------------------- */}
          <Route path='/sportsfederation' element={<SportsFederation />} />
          <Route path='/contactus' element={<ContactUs />} />
          <Route path='/disclaimer' element={<Disclaimer />} />
          <Route path='/eventorg' element={<EventOrg />} />
          <Route path='/highlight' element={<NewHighlight />} />


          {/* ------------------------downloads------------------------ */}



          <Route path='/notices' element={<NoticesCirculars />} />
          <Route path='/annual-report' element={<AnnualReport />} />
          <Route path='/advertisements' element={<Advertisement />} />
          <Route path='/press-release' element={<PressRelease />} />
          <Route path='/annual-calender' element={<AnnualCalender />} />
          <Route path="/letters" element={<Letters />} />


          {/* -------------------------sitemap------------------------------- */}
          <Route path='/sitemap' element={<Sitemap />} />



          {/* --------------------------National games section--------------------------- */}

          <Route path='/nationalgames' element={<NationalGames />} />
          <Route path='/gamedetails' element={<GameDetailsPage />} />
          <Route path='/38thnationalgames' element={<Th38_NationalGames />} />

          {/* rajgirstadiuminauguration */}

          <Route path="/rajgir-stadium-inauguration" element={<Rajgirstadiuminauguration />} />
          {/* BiharVolleyballLeague */}

          <Route path="/Bihar-Volleyball-League" element={<BiharVolleyballLeague />} />

          {/* --------------------National school games (SGFI)------------------------ */}

          <Route path='/national-school-games' element={<NationalSchoolGames />} />
          <Route path='/athlet' element={<Athlite />} />
          <Route path='/cricket' element={<Cricket />} />
          <Route path='/football' element={<Football />} />
          <Route path='/sepakTakra' element={<SepakTakra />} />
          <Route path='/weightlifting' element={<WeightLifting />} />

          {/*  */}
          <Route path="/bihar-Women-Kabaddi-League" element={<BiharWomenKabaddiLeague />} />
          <Route path="/bihar-Women-Kabaddi-Match-Schedule" element={<BiharWomenKabaddiMatchSchedule />} />

          <Route path="/olympic-Schedule-Table" element={<ScheduleTable />} />

          {/* ------------------------ checking for framer motion */}
          {/* <Route path='/box' element={<InternationalAchievement />} /> */}
          {/* career and opportunity  */}
          <Route path='/careerandopportunity' element={<CareerAndOpportunity />} />

          {/* ------------------- ProKabadiLinks---------------------------- */}
          <Route path='/esportlinks' element={<Esport />} />

          {/* ----------------------------- Pro kabaddi ----------------------- */}
          <Route path='/prokabaddi' element={<ProKabadiPage />} />


        </Routes>
      </Router>

    </div>
  );
}
export default App;
