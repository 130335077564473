import React from "react";
import Styled from "./NewInfoButton.module.css";

const NewInfoButton = () => {
  return (
    // <a href="img/notices/Final_List_02_11_2023.pdf" target="_blank">
    //     <div className={`${Styled.circle}`} style={{textAlign:'center', fontSize:".8rem", fontweight:"800"}}>
    //         मेडल लाओ नौकरी पाओ की फाइनल मेरिट सूची
    //     </div>
    // </a>
    //     <a href="https://mybharat.gov.in/yuva_register" target="_blank">
    //     <div className={`${Styled.circle}`} style={{textAlign:'center', fontSize:".8rem", fontweight:"800"}}>
    //     खेलो इंडिया प्रतिभा खोज के लिए रजिस्ट्रेशन करें
    //     </div>
    // </a>
    //     <a href="https://linktr.ee/univsports" target="_blank">
    //     <div className={`${Styled.circle}`} style={{textAlign:'center', fontSize:".8rem", fontweight:"800"}}>
    //     खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस के लिए रजिस्ट्रेशन करें
    //     </div>
    // </a>
    // <a href="img/Result/WL Shorlisted Athletes.pdf" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{ textAlign: "center", fontSize: ".8rem", fontweight: "800" }}
    //   >
    //     खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस ट्रायल ( भारोत्तोलन ) परिणाम
    //   </div>
    // </a>

    // <a href="img/Result/Westler_athfff_merged.pdf" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{ textAlign: "center", fontSize: ".8rem", fontweight: "800" }}
    //   >
    //     खेलो इंडिया स्टेट सेंटर ऑफ एक्सिलेंस ट्रायल ( एथलेटिक्स एवं कुश्ती)
    //     परिणाम
    //   </div>
    // </a>

    // <a href="img/notices/कुश्ती_merged.pdf" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{ textAlign: "center", fontSize: ".8rem", fontweight: "800" }}
    //   >
    //     कुश्ती, भारोत्तोलन एवं एथलेटिक्स के लिए चयनित खिलाड़ियों की सूची
    //   </div>
    // </a>
    // <a href="https://kss.biharsports.org/auth/login?next=/" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{ textAlign: "center", fontSize: ".8rem", fontweight: "800" }}
    //   >
    //     बिहार राज्य खेल सम्मान - 2024 के लिए रजिस्ट्रेशन करें
    //   </div>
    // </a>
    // <a href="https://kss.biharsports.org/auth/login?next=/" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{
    //       textAlign: "center",
    //       fontSize: ".8rem",
    //       fontweight: "800",
    //     }}
    //   >
    //     खेल सम्मान - 2024 के लिए आवेदन की अंतिम तिथि : 31 जुलाई 2024
    //   </div>
    // </a>
    // <a href="img/notices/rejected_List_For_KSS2024.pdf" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{
    //       textAlign: "center",
    //       fontSize: "1.5rem",
    //       fontweight: "800",
    //     }}
    //   >
    //     {/* खेल सम्मान समारोह - 2024 के अंतर्गत खिलाड़ियों से प्राप्त आवेदनों के जांच के उपरांत अस्वीकृत आवेदनों की सूची */}
    //     खेल सम्मान - 2024 : अस्वीकृत आवेदनों की सूची
    //   </div>
    // </a>
    // <a href="img/notices/KSS_2024_Final_Awardee_List.pdf" target="_blank">
    //   <div
    //     className={`${Styled.circle}`}
    //     style={{
    //       textAlign: "center",
    //       fontSize: "1rem",
    //       fontweight: "800",
    //     }}
    //   >
    //     {/* खेल सम्मान समारोह - 2024 के अंतर्गत खिलाड़ियों से प्राप्त आवेदनों के जांच के उपरांत अस्वीकृत आवेदनों की सूची */}
    //     खेल सम्मान - 2024:- स्वीकृत पुरस्कृतों की सूची ( प्रकाशित:- तिथि:- 04 सितंबर 2024, समय:- 12:07 अपराह्न )
    //   </div>
    // </a>

    <a href="https://club.biharsports.org" target="_blank">
      <div
        className={`${Styled.circle}`}
        style={{
          textAlign: "center",
          fontSize: "1.5rem",
          fontweight: "800",
        }}
      >
        बिहार पंचायत खेल क्लब पंजीकरण
      </div>
    </a>

  );
};

export default NewInfoButton;
